/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import React from "react";
import DoctorContactPageTemplate from "../../templates/doctor-contact-page";
const MDXLayout = () => React.createElement(DoctorContactPageTemplate, {
  doctor: "dr_soljan"
});
function _createMdxContent(props) {
  return React.createElement(React.Fragment);
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;

import { graphql, useStaticQuery } from "gatsby";
import React from "react";

const ContactDetails = () => {
  const {
    site: {
      siteMetadata: {
        contactData: { secretary },
      },
    },
  }: QueryType = useStaticQuery(query);

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-1">
        <h3 className="text-lg font-bold">Psychotherapiepraxis Düsseldorf</h3>
        <p className="text-slate-700">{secretary.streetAddress}</p>
        <p className="text-slate-700">
          {secretary.zipCode} {secretary.city}
        </p>
      </div>

      <div className="flex flex-col gap-2">
        <dl className="grid grid-cols-12">
          <dt className="col-span-4">Telefon:</dt>
          <dd className="col-span-8">
            <a href={`tel:${secretary.phone}`}>{secretary.phone}</a>
          </dd>
        </dl>

        <dl className="grid grid-cols-12">
          <dt className="col-span-4">Fax:</dt>
          <dd className="col-span-8">
            <a href={`tel:${secretary.fax}`}>{secretary.fax}</a>
          </dd>
        </dl>

        <dl className="grid grid-cols-12">
          <dt className="col-span-4">E-Mail:</dt>
          <dd className="col-span-8">
            <a href={`mailto:${secretary.email}`}>{secretary.email}</a>
          </dd>
        </dl>
      </div>

      <p className="text-slate-700">{secretary.hours}</p>
    </div>
  );
};

const query = graphql`
  query ContactDetailsQuery {
    site {
      siteMetadata {
        contactData {
          secretary {
            streetAddress
            zipCode
            city
            phone
            email
            fax
            hours
          }
        }
      }
    }
  }
`;

type QueryType = {
  site: {
    siteMetadata: {
      contactData: {
        secretary: {
          streetAddress: string;
          zipCode: string;
          city: string;
          phone: string;
          email: string;
          fax: string;
          hours: string;
        };
      };
    };
  };
};

export default ContactDetails;

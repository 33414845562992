import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { DoctorNameType } from "../../../types/app";
import IconMail from "../icons/mail";
import IconPhone from "../icons/phone";

export interface ContactDetailsDoctorProps {
  doctor: DoctorNameType;
}

const ContactDetailsDoctor: React.FC<ContactDetailsDoctorProps> = ({
  doctor,
}) => {
  const {
    site: {
      siteMetadata: { contactData },
    },
  }: QueryType = useStaticQuery(query);

  return (
    <div className="flex flex-col gap-2">
      <h3 className="text-lg font-bold">{contactData[doctor].name}</h3>
      <a
        className="flex flex-row items-center gap-2 text-sm"
        href={`mailto:${contactData[doctor].email}`}
      >
        <IconMail className="w-4 h-4" />
        <span>{contactData[doctor].email}</span>
      </a>
      <a
        className="flex flex-row items-center gap-2 text-sm"
        href={`tel:${contactData[doctor].phone}`}
      >
        <IconPhone className="w-4 h-4" />
        <span>{contactData[doctor].phone}</span>
      </a>
    </div>
  );
};

const query = graphql`
  query ContactDetailsDoctorQuery {
    site {
      siteMetadata {
        contactData {
          dr_soljan {
            name
            phone
            email
          }
          dr_salamon {
            name
            phone
            email
          }
          dr_schoettes {
            name
            phone
            email
          }
        }
      }
    }
  }
`;

type QueryType = {
  site: {
    siteMetadata: {
      contactData: {
        [key in DoctorNameType]: {
          phone: string;
          email: string;
          name: string;
        };
      };
    };
  };
};

export default ContactDetailsDoctor;

import { HeadFC } from "gatsby";
import React from "react";
import { DoctorNameType } from "../../types/app";
import Breadcrumbs from "../components/breadcrumbs";
import ContactDetailsDoctor from "../components/contact/contact-detail-doctor";
import ContactDetails from "../components/contact/contact-details";
import SEO from "../components/seo";

export interface DoctorContactPageProps {
  doctor: DoctorNameType;
}

const DoctorContactPage: React.FC<DoctorContactPageProps> = ({ doctor }) => {
  return (
    <div className="w-full">
      <div className="flex flex-col gap-8 py-6 mx-auto container-7xl">
        <div className="col-span-12">
          <Breadcrumbs />
        </div>
        <h1 className="text-3xl">Kontakt</h1>
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 md:col-span-6">
            <ContactDetailsDoctor doctor={doctor} />
          </div>
          <p className="col-span-12 text-sm md:col-span-6">
            Wir arbeiten ausschließlich nach individueller Terminvergabe.
            Zugelassen für alle privaten und allgemeinen Krankenkassen.
          </p>
        </div>
        <hr />
        <div className="grid items-center w-full grid-cols-12 gap-6">
          <div className="col-span-12 md:col-span-6">
            <ContactDetails />
          </div>
          <div className="col-span-12 md:col-span-6 h-96">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8953.57643648872!2d6.851997726023691!3d51.238288925027454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b8c95163074ef1%3A0x349b9e7eda0711e1!2sBenderstra%C3%9Fe+76%2C+40625+D%C3%BCsseldorf!5e0!3m2!1sen!2sde!4v1438370814917"
              className="w-full h-full rounded-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const Head: HeadFC = () => <SEO />;

export default DoctorContactPage;
